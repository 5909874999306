// Anything exported from this file is importable by other in-browser modules.
// import "keycloak-js"
import Keycloak from 'keycloak-js'

const keycloak = new Keycloak(`https://${window.location.host}/keycloak.json`);

export function getAuthProvider() {
    return keycloak;
}


export function getLoggedIn() {
    return keycloak.authenticated
}